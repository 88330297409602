.site-layout-content {
  min-height: 300px;
  padding: 12px;
  background: #fff;
}
.logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
}
.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}
[data-theme="dark"] .site-layout-content {
  background: #141414;
}

Header {
  color: #ffffff !important;
}

.card-container {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align items at the start of the cross axis */
  height: 100vh;
}

.card-content {
  display: flex;
  flex-wrap: wrap; /* Allow content to wrap */
  width: 100%;
}

.left-section {
  padding: 8px;
}

.right-section {
  flex-basis: 50%; /* Set the initial width of the right section */
  padding: 8px;
}

body {
  margin: 0 !important;
}
